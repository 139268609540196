<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <router-view />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>